<script setup lang="ts">
import { useCompanyStore } from '@/stores/companies'
import { useUserStore } from '@/stores/user'

const companiesStore = useCompanyStore()
const userStore = useUserStore()

const { $userCan } = useNuxtApp()
</script>

<template>
  <div class="max-w-full min-h-screen flex flex-col bg-base-200">
    <slot name="admin-header">
      <ClientOnly>
        <ClientViewBar
          v-if="
            companiesStore.clientViewCompany &&
            companiesStore.clientViewCompany?.Id &&
            userStore.getSystemRole != 'client_user'
          "
        ></ClientViewBar>
      </ClientOnly>
    </slot>
    <slot name="header">
      <ClientOnly>
        <Header></Header>
      </ClientOnly>
    </slot>
    <slot name="progress" />
    <ClientOnly>
      <main class="container grid grid-cols-6 gap-4">
        <div
          v-if="
            $userCan('read', 'users/client') &&
            $userCan('read', 'users/internal')
          "
          class="sidebar mt-5"
        >
          <h4 class="pl-5">Users</h4>
          <ul class="menu w-full pt-0">
            <li>
              <NuxtLink to="/users/internal/">Internal</NuxtLink>
            </li>
            <li>
              <NuxtLink to="/users/client/">Client</NuxtLink>
            </li>
          </ul>
        </div>
        <div class="col-span-5">
          <slot />
        </div>
      </main>
    </ClientOnly>
    <slot name="footer">
      <Footer></Footer>
    </slot>
  </div>
</template>
